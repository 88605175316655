import { CookieAlertService } from './../services/cookie-alert/cookie-alert.service';
import { BasketService } from './../services/firestore-data/basket/basket.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { Basket } from '../models/shop/basket/baket.model';
import { BasketState } from '../models/enums/basket-state.enum';
import { UserProfileService } from '../services/firestore-data/user-profile/user-profile.service';
import { UserProfile } from '../models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private userProfileService: UserProfileService,
    private basketService: BasketService,
    private cookieAlertService: CookieAlertService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((user: firebase.User) => {
        if (user) {
          resolve(true);
        } else {
          this.cookieAlertService.presentCookieAlert();
          this.afAuth
            .signInAnonymously()
            .then((auth) => {
              const newUserProfile: UserProfile = {
                id: auth.user.uid,
              };
              this.userProfileService.set(newUserProfile).then(() => {
                this.basketService.createNewBasketForCurrentUSer();
              });
              resolve(true);
            })
            .catch(() => {
              console.log('error could not sign in');
              reject(false);
            });
        }
      });
    });
  }
}
