import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class CookieAlertService {
  constructor(
    private toastController: ToastController,
    private router: Router
  ) {}


  //TODO: ABSTRACT THIS

  async presentCookieAlert() {
    const toast = await this.toastController.create({
      header: 'Wir verwenden Cookies',
      message:
        // eslint-disable-next-line max-len
        'Für weitere Informationen zu den von uns verwendeten Cookies öffnen Sie die Datenschutzseite.',
      position: 'top',
      color: 'dark',
      duration: 30000,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            console.log('Cancel clicked');
            // this.router.navigateByUrl('start/code');
          },
        },

      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
