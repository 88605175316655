import { CompleteUserProfileGuard } from './core/guards/complete-user-profile/complete-user-profile.guard';
// import { MissingUserInfoGuardGuard } from './core/guards/missing-user-info/missing-user-info-guard.guard';
// import { SetupHerbyWifiModule } from './core/components/client/devices/herby/setup-herby-wifi/setup-herby-wifi.module';
import { AdminPageGuard } from './core/guards/admin-page.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./core/pages/home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: 'admin',
    loadChildren: () =>
      import('./core/pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [AdminPageGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/pages/auth/auth.module').then((m) => m.AuthPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./core/pages/app/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard, CompleteUserProfileGuard],
  },
  // {
  //   path: 'tabs/:id',
  //   loadChildren: () => import('./core/pages/app/tabs/tabs.module').then((m) => m.TabsPageModule),
  //   canActivate: [],
  // },
  {
    path: 'prev/:id',
    loadChildren: () =>
      import('./core/pages/main/main.module').then((m) => m.MainPageModule),
    canActivate: [AuthGuard],
    // canActivate: [AngularFireAuthGuard]
  },
  // {
  //   path: 'app/dashboard',
  //   loadChildren: () =>
  //     import('./core/pages/app/dashboard/dashboard.module').then(
  //       (m) => m.DashboardPageModule
  //     ),
  //   canActivate: [AuthGuard, Com],
  // },
  {
    path: 'basket',
    loadChildren: () =>
      import('./core/pages/client/shop/basket/basket.module').then(
        (m) => m.BasketPageModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./core/pages/client/shop/checkout/checkout.module').then(
        (m) => m.CheckoutPageModule
      ),
  },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./core/pages/client/on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./core/pages/published/published.module').then(
        (m) => m.PublishedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'knowledge/:id',
    loadChildren: () =>
      import('./core/pages/published/published.module').then(
        (m) => m.PublishedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'knowledge/plants/:id',
    loadChildren: () =>
      import('./core/pages/published/published.module').then(
        (m) => m.PublishedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'checkout-success/:id',
    loadChildren: () =>
      import(
        './core/pages/client/shop/checkout/checkout-success/checkout-success.module'
      ).then((m) => m.CheckoutSuccessPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/add-reduction-goal',
    loadChildren: () => import('./custom/pages/app/reduction/add-reduction-goal/add-reduction-goal/add-reduction-goal.module').then( m => m.AddReductionGoalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/reduction-goal-detail/:id',
    loadChildren: () => import('./custom/pages/app/reduction/reduction-goal-detail/reduction-goal-detail.module').then( m => m.ReductionGoalDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/add-increase-goal',
    loadChildren: () => import('./custom/pages/app/increase/add-increase-goal/add-increase-goal/add-increase-goal.module').then( m => m.AddIncreaseGoalPageModule),
    canActivate: [AuthGuard]
  },
  
  // {
  //   path: ':parent/:id',
  //   loadChildren: () =>
  //     import('./pages/main/main.module').then((m) => m.MainPageModule),
  //   canActivate: [],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
