// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAz7JazpogaZsarscTgI9RJ8BHQEYY_tWI",
    authDomain: "johnnys-app-dev.firebaseapp.com",
    projectId: "johnnys-app-dev",
    storageBucket: "johnnys-app-dev.appspot.com",
    messagingSenderId: "983755732178",
    appId: "1:983755732178:web:da380219e9290995a8e90b",
    measurementId: "G-027N2T0JFP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
